// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_am1D70zYPzg3lMQ-r_ITZ0SuUgM5Vsw",
  authDomain: "luxafor-register.firebaseapp.com",
  projectId: "luxafor-register",
  storageBucket: "luxafor-register.appspot.com",
  messagingSenderId: "1097192205949",
  appId: "1:1097192205949:web:a4419d523bf0de356e8b14"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);


export { auth }