import { useState, useEffect } from 'react';
import './App.css';

import { auth } from './firebase'
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

import Logo from './assets/Luxafor_logo.png'

import Flag from './assets/flag.webp'
import Orb from './assets/orb.png'

function App() {


  const [user, setUser] = useState<any>(null)
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setUser(user);
        ////////////////////////////////////////////////////// Access Token ////////////////////////////////////////////////////////
        user.getIdToken().then((accessToken : any) => {
          console.log("Access Token:", accessToken);
        }).catch((error: any) => {
          console.error("Error getting access token:", error);
        });

      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);



  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (password.length >= 6){
      createUserWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    }

  };

  return (
    <div className="App font-primary">

      <div className='max-w-7xl relative m-auto flex h-screen'>

        <img
          src={Flag}
          className='absolute w-96 -z-10'
        />

        <img
          src={Orb}
          className='absolute w-96 -z-10 bottom-0 right-0'
        />

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

          <img
            className="mx-auto h-48 w-auto -mb-4"
            src={Logo}
            alt="Your Company"
          />

          {user == null ? <div>
            <h2 className="-mb-8 text-center text-2xl font-bold tracking-tight text-gray-900">
              Register
            </h2>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm text-start">
              <form onSubmit={handleSubmit} className="space-y-6" >

                <div className="mt-2">
                  <label className="block text-sm font-semibold  text-black mb-2 ml-1">
                    Email
                  </label>
                  <input
                    className="block w-full rounded-2xl  px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />

                </div>

                <div className="mt-2">

                  <label htmlFor="password" className="block text-sm font-semibold  text-black mb-2 ml-1">
                    Password
                  </label>

                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="block w-full rounded-2xl  px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                  <p className='text-[10px] ml-1'>Password must be atleast 6 characters long</p>

                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center mt-10 rounded-2xl bg-[#59bb58] px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#59bb58] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#59bb58]"
                  >
                    Register
                  </button>
                </div>
              </form>


            </div>
          </div>
            :
            <div>
              <h2 className=" text-center text-2xl font-bold tracking-tight text-gray-900">
                Thank you!
              </h2>
              <p>
                Your registration was successful!
              </p>
              {user && user.email && <p className='text-sm mt-2 font-semibold'>
                {user.email}
              </p>
              }
            </div>
          }

        </div>

      </div>
    </div>
  );
}

export default App;
